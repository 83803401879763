export enum LpplTemplateEnum {
    VERIFY_SCAN_REG = "verify_scan_reg",
    VERIFY_SCAN_REG_HI = "hi_verify_scan_reg",
    VERIFY_SCAN_REGISTERED_HI = "hi_verify_scan_registered",
    VERIFY_SCAN_UNREG = "verify_scan_unreg",
    VERIFY_SCAN_UNREG_HI = "hi_verify_scan_unreg",
    UPLOAD_ASK = "upload_ask",
    UPLOAD_ASK_HI = "hi_upload_ask",
    CHANGE_LANGUAGE = "change_language",
    CHANGE_LANGUAGE_HI = "hi_change_lang",
    KYC_PROMPT = "kyc_prompt",
    KYC_PROMPT_HI = "hi_kyc_prompt",
    WELCOME_REG = "welcome_mgs_reg",
    WELCOME_UNREG_HI = "hi_welcome_msg",
    WELCOME_UNREG = "welcome_mgs_unreg",
    INVALID_ENTRY = "invalid_entry",
    INVALID_ENTRY_HI = "hi_invalid_entry",
    INVALID_CODE = "invalid_code",
    INVALID_CODE_HI = "hi_invalid_code",
    PAYOUT = "payout",
    PAYOUT_HI = "hi_payout",
    REDEEM_DENIED = "redeem_denied",
    REDEEM_DENIED_HI = "hi_redeem_denied",
    REDEEM_CONFIRM = "redeem_confirm",
    REDEEM_CONFIRM_HI = "hi_redeem_confirmed",
    CHECK_BAL_IF250 = "check_balance_if_250",
    CHECK_BAL_IF250_HI = "hi_check_bal_if_250",
    CHECK_BAL_IF_NOT250 = "check_balance_if_not_250",
    CHECK_BAL_IF_NOT250_HI = "hi_check_bal_if_not_250",
    REDEEM_ASK = "redeem_ask",
    REDEEM_ASK_HI = "hi_redeem_ask",
    KYC_COMPLETE = "kyc_complete",
    KYC_COMPLETE_HI = "hi_kyc_complete",
    KYC_IN_PROGRESS = "kyc_in_prog",
    KYC_IN_PROGRESS_HI = "hi_kyc_in_prog",
    KYC_FAILED = "kyc_failed",
    KYC_FAILED_HI = "hi_kyc_failed"
}