import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { WhatsAppTemplate } from 'src/app/views/pages/company-dashboard/models/whatsapp-template.model';
import { MetaWaService } from 'src/app/views/pages/company-dashboard/services/meta-wa.service';
import { LpplTemplateEnum } from '../constants/lppl-template-name-enum';
import { WaTemplatePayload } from 'src/app/views/pages/company-dashboard/models/wa-template-payload';
import { LpplTemplateButtonIdEnum } from '../constants/lppl-template-button-id-enum';

enum Steps {
  TEMPLATE_SELECTION = "Template Selection",
  SEND_MESSAGE = "Send Message"
};

@Component({
  selector: 'kt-wa-template-list',
  templateUrl: './wa-template-list.component.html',
  styleUrls: ['./wa-template-list.component.scss']
})
export class WaTemplateListComponent implements OnInit {

  private readonly LANGUAGE_CODE_EN = 'en';
  private readonly LANGUAGE_CODE_HI = 'hi';
  private readonly BUTTON_SUBTYPE_FLOW = 'flow';
	private readonly BUTTON_SUBTYPE_QUICK_REPLY = 'quick_reply';

  companyId: number;
  customerWaNumber: string;
  selectedProject = JSON.parse(localStorage.getItem('selectedProject'));

  templates: WhatsAppTemplate[] = [];
  selectedTemplate: WhatsAppTemplate;
  currentStep: Steps = Steps.TEMPLATE_SELECTION;
  STEPS = Steps;
  isTemplateSelected = false;
  hasEmptyFields = true;
  spinnerText = 'Fetching templates...';
  originalMessageContent: string = '';
  messageContent: string = '';
  headerMessage: string = '';
  bodyMessage: string = '';
  buttonMessage: string = '';
  buttonList: string[] = [];
  headerVariables: string[] = [];
  bodyVariables: string[] = [];
  userInputForHeaderVariables: string[] = [];
  userInputForBodyVariables: string[] = [];

  constructor(
    private spinner: NgxSpinnerService,
    public activeModal: NgbActiveModal,
    private metaWaService: MetaWaService) { }

  ngOnInit(): void {
    this.fetchTemplates();
  }

  fetchTemplates() {
    this.spinner.show('fetching-templates');
    this.metaWaService.fetchAllTemplates().then(allTemplates => {
      this.templates = allTemplates;
      this.templates.forEach(template => template.varibaleCount = this.countVariablesInTemplate(template));
      this.spinner.hide('fetching-templates');
    }).catch(error => {
      console.error('Error fetching templates:', error);
      this.spinner.hide('fetching-templates');
    });
  }

  private countVariablesInTemplate(template: WhatsAppTemplate): number {
    let variableCount = 0;

    template.components.forEach(component => {
      if (component?.text) {
        const matches = component.text.match(/\{\{\d+\}\}/g);
        if (matches) {
          variableCount += matches.length;
        }
      }
      if (component?.format === 'DOCUMENT') {
        template.hasMedia = true;
      }
    });
    return variableCount;
  }

  selectTemplate(template: WhatsAppTemplate) {
    this.selectedTemplate = template;
    this.isTemplateSelected = true;
  }

  onConfirm(): void {
    this.currentStep = Steps.SEND_MESSAGE;
    this.initializeVariables();
  }

  previousTab() {
    if (this.currentStep === Steps.SEND_MESSAGE) {
      this.currentStep = Steps.TEMPLATE_SELECTION;
      this.headerVariables = [];
      this.bodyVariables = [];
      this.hasEmptyFields = true;
    }
  }

  onClose(): void {
    this.activeModal.close();
  }

  initializeVariables() {
    this.originalMessageContent = this.extractTextFromTemplate(this.selectedTemplate);
    this.messageContent = this.originalMessageContent;
    if (this.headerVariables.length == 0 && this.bodyVariables.length == 0) {
      this.hasEmptyFields = false;
    }
  }

  extractTextFromTemplate(template: WhatsAppTemplate): string {
    this.buttonMessage = '';
    template.components.forEach(component => {
      if (component.type === 'HEADER') {
        // header text
        if (component.text) {
          this.headerMessage = this.removeAsterisksFromText(component.text);
          const headerVariableMatches = this.headerMessage.match(/\{\{\d+\}\}/g);
          if (headerVariableMatches) {
            this.headerVariables = headerVariableMatches;
            this.userInputForHeaderVariables = new Array(this.headerVariables.length).fill('');
          }
        }
      } else if (component.type === 'BODY') {
        // body text
        if (component.text) {
          this.bodyMessage = this.removeAsterisksFromText(component.text);
          const bodyVariableMatches = this.bodyMessage.match(/\{\{\d+\}\}/g);
          if (bodyVariableMatches) {
            this.bodyVariables = bodyVariableMatches;
            this.userInputForBodyVariables = new Array(this.bodyVariables.length).fill('');
          }
        }
      } else if (component.type === 'BUTTONS' && component.buttons) {
        // button texts
        component.buttons.forEach(button => {
          if (button.text) {
            this.buttonMessage += button.text + '\n';
            this.buttonList.push(button.text);
          }
        });
      }
    });

    const fullMessageContent = `${this.headerMessage}\n\n${this.bodyMessage}\n\n${this.buttonMessage}`;
    return fullMessageContent?.trim();
  }

  private removeAsterisksFromText(text: string | undefined): string {
    return text ? text.replace(/\*/g, '') : '';
  }

  updateMessageContent() {
    let formattedHeaderContent = this.headerMessage;
    let formattedBodyContent = this.bodyMessage;

    this.userInputForHeaderVariables.forEach((value, index) => {
      const regex = new RegExp(`\\{\\{${index + 1}\\}\\}`, 'g');
      formattedHeaderContent = formattedHeaderContent.replace(regex, value || `{{${index + 1}}}`);
    });

    this.userInputForBodyVariables.forEach((value, index) => {
      const regex = new RegExp(`\\{\\{${index + 1}\\}\\}`, 'g');
      formattedBodyContent = formattedBodyContent.replace(regex, value || `{{${index + 1}}}`);
    });

    this.messageContent = `${formattedHeaderContent}\n\n${formattedBodyContent}\n\n${this.buttonMessage}`;
    this.hasEmptyFields = this.userInputForHeaderVariables.concat(this.userInputForBodyVariables).some(value => !value);
  }

  onSendMessage() {
    this.spinnerText = 'Sending message...';
    this.spinner.show('fetching-templates');
    const templatePayload = this.prepareTemplatePayload(this.selectedTemplate.name, this.companyId, this.selectedProject, this.customerWaNumber);
    this.metaWaService.sendTemplateMessage(templatePayload).subscribe(response => {
      this.spinner.hide('fetching-templates');
      this.activeModal.close("SUCCESS");
    }, error => {
      this.spinner.hide('fetching-templates');
      console.error('Error sending message:', error);
      this.activeModal.close("ERROR");
    });
  }


  private prepareTemplatePayload(templateName: string, companyId: number, projectId: number, customerWaNumber: string): WaTemplatePayload {
    switch (templateName) {
      case LpplTemplateEnum.VERIFY_SCAN_REG:
        return this.prepareVerifyScanRegPayload(projectId, companyId, customerWaNumber, templateName, this.LANGUAGE_CODE_EN);
      case LpplTemplateEnum.VERIFY_SCAN_REG_HI:
        return this.prepareVerifyScanRegPayload(projectId, companyId, customerWaNumber, templateName, this.LANGUAGE_CODE_HI);
      case LpplTemplateEnum.VERIFY_SCAN_REGISTERED_HI:
        return this.prepareVerifyScanRegPayload(projectId, companyId, customerWaNumber, templateName, this.LANGUAGE_CODE_HI);
      case LpplTemplateEnum.VERIFY_SCAN_UNREG:
        return this.prepareVerifyScanUnRegPayload(projectId, companyId, customerWaNumber, templateName, this.LANGUAGE_CODE_EN);
      case LpplTemplateEnum.VERIFY_SCAN_UNREG_HI:
        return this.prepareVerifyScanUnRegPayload(projectId, companyId, customerWaNumber, templateName, this.LANGUAGE_CODE_HI);
      case LpplTemplateEnum.UPLOAD_ASK:
        return this.getHeaderAndBodyVariables(projectId, companyId, customerWaNumber, templateName, this.LANGUAGE_CODE_EN);
      case LpplTemplateEnum.UPLOAD_ASK_HI:
        return this.getHeaderAndBodyVariables(projectId, companyId, customerWaNumber, templateName, this.LANGUAGE_CODE_HI);
      case LpplTemplateEnum.CHANGE_LANGUAGE:
        return this.prepareChangeLanguagePayload(projectId, companyId, customerWaNumber, templateName, this.LANGUAGE_CODE_EN);
      case LpplTemplateEnum.CHANGE_LANGUAGE_HI:
        return this.prepareChangeLanguagePayload(projectId, companyId, customerWaNumber, templateName, this.LANGUAGE_CODE_HI);
      case LpplTemplateEnum.KYC_PROMPT:
        return this.prepareKycPromptPayload(projectId, companyId, customerWaNumber, templateName, this.LANGUAGE_CODE_EN);
      case LpplTemplateEnum.KYC_PROMPT_HI:
        return this.prepareKycPromptPayload(projectId, companyId, customerWaNumber, templateName, this.LANGUAGE_CODE_HI);
      case LpplTemplateEnum.WELCOME_REG:
        return this.prepareWelcomeRegPayload(projectId, companyId, customerWaNumber, templateName, this.LANGUAGE_CODE_EN);
      case LpplTemplateEnum.WELCOME_UNREG_HI:
        console.warn('WELCOME_UNREG_HI template is not supported yet');
        break;
      case LpplTemplateEnum.WELCOME_UNREG:
        return this.prepareWelcomeUnRegPayload(projectId, companyId, customerWaNumber, templateName, this.LANGUAGE_CODE_EN);
      case LpplTemplateEnum.INVALID_ENTRY:
        return this.prepareInvalidEntryPayload(projectId, companyId, customerWaNumber, templateName, this.LANGUAGE_CODE_EN);
      case LpplTemplateEnum.INVALID_ENTRY_HI:
        return this.prepareInvalidEntryPayload(projectId, companyId, customerWaNumber, templateName, this.LANGUAGE_CODE_HI);
      case LpplTemplateEnum.INVALID_CODE:
        return this.prepareInvalidCodePayload(projectId, companyId, customerWaNumber, templateName, this.LANGUAGE_CODE_EN);
      case LpplTemplateEnum.INVALID_CODE_HI:
        return this.prepareInvalidCodePayload(projectId, companyId, customerWaNumber, templateName, this.LANGUAGE_CODE_HI);
      case LpplTemplateEnum.PAYOUT:
        return this.preparePayoutPayload(projectId, companyId, customerWaNumber, templateName, this.LANGUAGE_CODE_EN);
      case LpplTemplateEnum.PAYOUT_HI:
          return this.preparePayoutPayload(projectId, companyId, customerWaNumber, templateName, this.LANGUAGE_CODE_HI);
      case LpplTemplateEnum.REDEEM_DENIED:
        return this.prepareRedeemDeniedPayload(projectId, companyId, customerWaNumber, templateName, this.LANGUAGE_CODE_EN);
      case LpplTemplateEnum.REDEEM_DENIED_HI:
          return this.prepareRedeemDeniedPayload(projectId, companyId, customerWaNumber, templateName, this.LANGUAGE_CODE_HI);
      case LpplTemplateEnum.REDEEM_CONFIRM:
        return this.prepareRedeemConfirmPayload(projectId, companyId, customerWaNumber, templateName, this.LANGUAGE_CODE_EN);
      case LpplTemplateEnum.REDEEM_CONFIRM_HI:
        return this.prepareRedeemConfirmPayload(projectId, companyId, customerWaNumber, templateName, this.LANGUAGE_CODE_HI);
      case LpplTemplateEnum.CHECK_BAL_IF250:
        return this.prepareCheckBalPayload(projectId, companyId, customerWaNumber, templateName, this.LANGUAGE_CODE_EN);
      case LpplTemplateEnum.CHECK_BAL_IF250_HI:
        return this.prepareCheckBalPayload(projectId, companyId, customerWaNumber, templateName, this.LANGUAGE_CODE_HI);
      case LpplTemplateEnum.CHECK_BAL_IF_NOT250:
        return this.prepareCheckBalPayload(projectId, companyId, customerWaNumber, templateName, this.LANGUAGE_CODE_EN);
      case LpplTemplateEnum.CHECK_BAL_IF_NOT250_HI:
        return this.prepareCheckBalPayload(projectId, companyId, customerWaNumber, templateName, this.LANGUAGE_CODE_HI);
      case LpplTemplateEnum.REDEEM_ASK:
        return this.prepareRedeemAskPayload(projectId, companyId, customerWaNumber, templateName, this.LANGUAGE_CODE_EN);
      case LpplTemplateEnum.REDEEM_ASK_HI:
          return this.prepareRedeemAskPayload(projectId, companyId, customerWaNumber, templateName, this.LANGUAGE_CODE_HI);
      case LpplTemplateEnum.KYC_COMPLETE:
        return this.prepareKycCompletePayload(projectId, companyId, customerWaNumber, templateName, this.LANGUAGE_CODE_EN);
      case LpplTemplateEnum.KYC_COMPLETE_HI:
        return this.prepareKycCompletePayload(projectId, companyId, customerWaNumber, templateName, this.LANGUAGE_CODE_HI);
      case LpplTemplateEnum.KYC_IN_PROGRESS:
        return this.getHeaderAndBodyVariables(projectId, companyId, customerWaNumber, templateName, this.LANGUAGE_CODE_EN);
      case LpplTemplateEnum.KYC_IN_PROGRESS_HI:
        return this.getHeaderAndBodyVariables(projectId, companyId, customerWaNumber, templateName, this.LANGUAGE_CODE_HI);
      case LpplTemplateEnum.KYC_FAILED:
        return this.prepareKycFailedPayload(projectId, companyId, customerWaNumber, templateName, this.LANGUAGE_CODE_EN);
      case LpplTemplateEnum.KYC_FAILED_HI:
        return this.prepareKycFailedPayload(projectId, companyId, customerWaNumber, templateName, this.LANGUAGE_CODE_HI);
      default:
        console.warn(`Unhandled template: ${templateName}`);
        break;
    }
  }

  private getHeaderAndBodyVariables(projectId: number, companyId: number, customerWaNumber: string, templateName: string, languageCode: string): WaTemplatePayload {
    let payload: WaTemplatePayload = {
      projectId: projectId,
      companyId: companyId,
      customerWaNumber: customerWaNumber,
      templateName: templateName,
      languageCode: languageCode,
      headerPayload: [],
      bodyPayload: [],
      buttonPayload: []
    };
    payload.projectId = projectId;
    payload.companyId = companyId;
    payload.templateName = templateName;
    this.userInputForHeaderVariables.forEach(value => {
      payload.headerPayload.push({ type: 'text', text: value });
    });
    this.userInputForBodyVariables.forEach(value => {
      payload.bodyPayload.push({ type: 'text', text: value });
    });
    return payload;
  }

  private prepareVerifyScanRegPayload(projectId: number, companyId: number, customerWaNumber: string, templateName: string, languageCode: string): WaTemplatePayload {
    let payload: WaTemplatePayload = this.getHeaderAndBodyVariables(projectId, companyId, customerWaNumber, templateName, languageCode);
    payload.buttonSubType = this.BUTTON_SUBTYPE_QUICK_REPLY;
    payload.buttonPayload.push({ index: '0', buttonId: LpplTemplateButtonIdEnum.RECORD_PURCHASE },
      { index: '1', buttonId: LpplTemplateButtonIdEnum.REQUEST_CALLBACK },
      { index: '2', buttonId: LpplTemplateButtonIdEnum.CHECK_POINTS_BALANCE }
    );
    return payload;
  }

  private prepareVerifyScanUnRegPayload(projectId: number, companyId: number, customerWaNumber: string, templateName: string, languageCode: string): WaTemplatePayload {
    let payload: WaTemplatePayload = this.getHeaderAndBodyVariables(projectId, companyId, customerWaNumber, templateName, languageCode);
    payload.buttonSubType = this.BUTTON_SUBTYPE_QUICK_REPLY;
    payload.buttonPayload.push({ index: '0', buttonId: LpplTemplateButtonIdEnum.REGISTER_NOW },
      { index: '1', buttonId: LpplTemplateButtonIdEnum.REQUEST_CALLBACK },
      { index: '2', buttonId: LpplTemplateButtonIdEnum.CHANGE_LANGUAGE }
    );
    return payload;
  }

  private prepareChangeLanguagePayload(projectId: number, companyId: number, customerWaNumber: string, templateName: string, languageCode: string): WaTemplatePayload {
    let payload: WaTemplatePayload = this.getHeaderAndBodyVariables(projectId, companyId, customerWaNumber, templateName, languageCode);
    payload.buttonSubType = this.BUTTON_SUBTYPE_QUICK_REPLY;
    payload.buttonPayload.push({ index: '0', buttonId: this.LANGUAGE_CODE_EN === languageCode ? LpplTemplateButtonIdEnum.HINDI : LpplTemplateButtonIdEnum.ENGLISH },
      { index: '1', buttonId: LpplTemplateButtonIdEnum.DONT_CHANGE }
    );
    return payload;
  }

  private prepareKycPromptPayload(projectId: number, companyId: number, customerWaNumber: string, templateName: string, languageCode: string): WaTemplatePayload {
    let payload: WaTemplatePayload = this.getHeaderAndBodyVariables(projectId, companyId, customerWaNumber, templateName, languageCode);
    payload.buttonSubType = this.BUTTON_SUBTYPE_FLOW;
    payload.buttonPayload.push({ index: '0', buttonId:  ''});
    return payload;
  }

  private prepareWelcomeRegPayload(projectId: number, companyId: number, customerWaNumber: string, templateName: string, languageCode: string): WaTemplatePayload {
    let payload: WaTemplatePayload = this.getHeaderAndBodyVariables(projectId, companyId, customerWaNumber, templateName, languageCode);
    payload.buttonSubType = this.BUTTON_SUBTYPE_QUICK_REPLY;
    payload.buttonPayload.push({ index: '0', buttonId:  LpplTemplateButtonIdEnum.REQUEST_CALLBACK },
      { index: '1', buttonId:  LpplTemplateButtonIdEnum.CHANGE_LANGUAGE } 
    );
    return payload;
  }

  private prepareWelcomeUnRegPayload(projectId: number, companyId: number, customerWaNumber: string, templateName: string, languageCode: string): WaTemplatePayload {
    let payload: WaTemplatePayload = this.getHeaderAndBodyVariables(projectId, companyId, customerWaNumber, templateName, languageCode);
    payload.buttonSubType = this.BUTTON_SUBTYPE_QUICK_REPLY;
    payload.buttonPayload.push({ index: '2', buttonId:  LpplTemplateButtonIdEnum.CHANGE_LANGUAGE },
      { index: '3', buttonId:  LpplTemplateButtonIdEnum.REGISTER_NOW } 
    );
    return payload;
  }

  private prepareInvalidEntryPayload(projectId: number, companyId: number, customerWaNumber: string, templateName: string, languageCode: string): WaTemplatePayload {
    let payload: WaTemplatePayload = this.getHeaderAndBodyVariables(projectId, companyId, customerWaNumber, templateName, languageCode);
    payload.buttonSubType = this.BUTTON_SUBTYPE_QUICK_REPLY;
    payload.buttonPayload.push({ index: '0', buttonId:  LpplTemplateButtonIdEnum.RECORD_PURCHASE },
      { index: '1', buttonId:  LpplTemplateButtonIdEnum.CHECK_POINTS_BALANCE }, 
      { index: '2', buttonId:  LpplTemplateButtonIdEnum.REQUEST_CALLBACK },
      { index: '3', buttonId:  LpplTemplateButtonIdEnum.CHANGE_LANGUAGE } 
    );
    return payload;
  }

  private prepareInvalidCodePayload(projectId: number, companyId: number, customerWaNumber: string, templateName: string, languageCode: string): WaTemplatePayload {
    let payload: WaTemplatePayload = this.getHeaderAndBodyVariables(projectId, companyId, customerWaNumber, templateName, languageCode);
    payload.buttonSubType = this.BUTTON_SUBTYPE_QUICK_REPLY;
    payload.buttonPayload.push({ index: '0', buttonId:  LpplTemplateButtonIdEnum.RECORD_PURCHASE },
      { index: '1', buttonId:  LpplTemplateButtonIdEnum.CHECK_POINTS_BALANCE }, 
      { index: '2', buttonId:  LpplTemplateButtonIdEnum.REQUEST_CALLBACK }
    );
    return payload;
  }
  private preparePayoutPayload(projectId: number, companyId: number, customerWaNumber: string, templateName: string, languageCode: string): WaTemplatePayload {
    let payload: WaTemplatePayload = this.getHeaderAndBodyVariables(projectId, companyId, customerWaNumber, templateName, languageCode);
    payload.buttonSubType = this.BUTTON_SUBTYPE_QUICK_REPLY;
    payload.buttonPayload.push({ index: '0', buttonId:  LpplTemplateButtonIdEnum.RECORD_PURCHASE },
      { index: '1', buttonId:  LpplTemplateButtonIdEnum.CHECK_POINTS_BALANCE }, 
      { index: '2', buttonId:  LpplTemplateButtonIdEnum.REQUEST_CALLBACK }
    );
    return payload;
  }

  private prepareRedeemDeniedPayload(projectId: number, companyId: number, customerWaNumber: string, templateName: string, languageCode: string): WaTemplatePayload {
    let payload: WaTemplatePayload = this.getHeaderAndBodyVariables(projectId, companyId, customerWaNumber, templateName, languageCode);
    payload.buttonSubType = this.BUTTON_SUBTYPE_QUICK_REPLY;
    payload.buttonPayload.push({ index: '0', buttonId:  LpplTemplateButtonIdEnum.RECORD_PURCHASE },
      { index: '1', buttonId:  LpplTemplateButtonIdEnum.REQUEST_CALLBACK }, 
      { index: '2', buttonId:  LpplTemplateButtonIdEnum.CHECK_POINTS_BALANCE }
    );
    return payload;
  }
  
  private prepareRedeemConfirmPayload(projectId: number, companyId: number, customerWaNumber: string, templateName: string, languageCode: string): WaTemplatePayload {
    let payload: WaTemplatePayload = this.getHeaderAndBodyVariables(projectId, companyId, customerWaNumber, templateName, languageCode);
    payload.buttonSubType = this.BUTTON_SUBTYPE_QUICK_REPLY;
    payload.buttonPayload.push({ index: '0', buttonId:  LpplTemplateButtonIdEnum.RECORD_PURCHASE },
      { index: '1', buttonId:  LpplTemplateButtonIdEnum.REQUEST_CALLBACK }, 
      { index: '2', buttonId:  LpplTemplateButtonIdEnum.CHECK_POINTS_BALANCE }
    );
    return payload;
  }

  private prepareCheckBalPayload(projectId: number, companyId: number, customerWaNumber: string, templateName: string, languageCode: string): WaTemplatePayload {
    let payload: WaTemplatePayload = this.getHeaderAndBodyVariables(projectId, companyId, customerWaNumber, templateName, languageCode);
    payload.buttonSubType = this.BUTTON_SUBTYPE_QUICK_REPLY;
    payload.buttonPayload.push({ index: '0', buttonId:  LpplTemplateButtonIdEnum.RECORD_PURCHASE },
      { index: '1', buttonId:  LpplTemplateButtonIdEnum.CHANGE_LANGUAGE }, 
      { index: '2', buttonId:  LpplTemplateButtonIdEnum.REQUEST_CALLBACK }
    );
    return payload;
  }

  private prepareRedeemAskPayload(projectId: number, companyId: number, customerWaNumber: string, templateName: string, languageCode: string): WaTemplatePayload {
    let payload: WaTemplatePayload = this.getHeaderAndBodyVariables(projectId, companyId, customerWaNumber, templateName, languageCode);
    payload.buttonSubType = this.BUTTON_SUBTYPE_QUICK_REPLY;
    payload.buttonPayload.push({ index: '0', buttonId:  LpplTemplateButtonIdEnum.CONFIRM_REDEMPTION },
      { index: '1', buttonId:  LpplTemplateButtonIdEnum.DONT_REDEEM }
    );
    return payload;
  }

  private prepareKycCompletePayload(projectId: number, companyId: number, customerWaNumber: string, templateName: string, languageCode: string): WaTemplatePayload {
    let payload: WaTemplatePayload = this.getHeaderAndBodyVariables(projectId, companyId, customerWaNumber, templateName, languageCode);
    payload.buttonSubType = this.BUTTON_SUBTYPE_QUICK_REPLY;
    payload.buttonPayload.push({ index: '0', buttonId:  LpplTemplateButtonIdEnum.RECORD_PURCHASE },
      { index: '1', buttonId:  LpplTemplateButtonIdEnum.CHECK_POINTS_BALANCE }, 
      { index: '2', buttonId:  LpplTemplateButtonIdEnum.CHANGE_LANGUAGE }
    );
    return payload;
  }

  private prepareKycFailedPayload(projectId: number, companyId: number, customerWaNumber: string, templateName: string, languageCode: string): WaTemplatePayload {
    let payload: WaTemplatePayload = this.getHeaderAndBodyVariables(projectId, companyId, customerWaNumber, templateName, languageCode);
    payload.buttonSubType = this.BUTTON_SUBTYPE_QUICK_REPLY;
    payload.buttonPayload.push({ index: '0', buttonId:  LpplTemplateButtonIdEnum.REGISTER_NOW },
      { index: '1', buttonId:  LpplTemplateButtonIdEnum.REQUEST_CALLBACK }
    );
    return payload;
  }

}
